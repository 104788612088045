import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { Endpoints, ApiMethod, colorCodes, SuccessMessage } from 'src/app/core/services/constants';
import { Roles } from 'src/app/modules/auth/model/user';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CpbookingService {

  constructor(private _http:HttpService, private _StorageService: StorageService, private _snackBar: SnackbarService,private _router: Router) { }

  getCpDetails(successCallback, payload?:any) {
    if (payload){
      this._http.requestCall(Endpoints.CP_DETAILS, ApiMethod.GETPARAMS,payload).subscribe(
        (res) => { successCallback(res) },
        (error) => {console.log(error) }
      )
    }else{
      this._http.requestCall(Endpoints.CP_DETAILS, ApiMethod.GET).subscribe(
        (res) => { successCallback(res) },
        (error) => {console.log(error) }
      )
    }
  }

  getCheck_cp(payload, successCallback){
    this._http.requestCall(Endpoints.Check_CP, ApiMethod.GETPARAMS,payload).subscribe((response) => {
      successCallback(response);
    }
   // (error) => { console.log(error); });
    )}

  getDistinctHospitals(payload, successCallback) {
    this._http.requestCall(Endpoints.GET_DISTINCT_HOSPITAL, ApiMethod.GETPARAMS, payload).subscribe(
      (res) => { successCallback(res);},
      (error) => { console.log(error) }
    )
  }


  getShippingAddress(payload, successCallback) {
      this._http.requestCall(Endpoints.GET_INVOICE_BILLTOSHIPTO, ApiMethod.GETPARAMS, payload).subscribe(
      (res) => { successCallback(res);},
      (error) => { console.log(error) }
    )
  }

  getSitesforHospital(hospitalNo, successCallback) {
    this._http.requestCall(Endpoints.GET_SITES_HOSPITAL, ApiMethod.POST, hospitalNo).subscribe(
      (res) => { successCallback(res) },
      (error) => { console.log(error); }
    )
  }

  getOtlBySiteId(siteId, successResponseCallback) {
    this._http.requestCall(Endpoints.GET_CP_STOCK_FOR_SALES, ApiMethod.GETPARAMS,siteId).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      }) 
  }

  getOtlPartsOpf(payload, successResponseCallback) {
    this._http.requestCall(Endpoints.GET_OTL_PARTS_OPF, ApiMethod.GETPARAMS, payload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error) 
      })
  }
  addBooking(payload, successCallBack){
    this._http.requestCall(Endpoints.ADD_BOOKING, ApiMethod.POST, payload).subscribe(
      (res) => {   
        successCallBack(res);
       },
      (error) => { 
        console.log(error); 
      }
    )
  }
  navigateTo(role, params?:any){
    this.navigateToOPFList();
    this._snackBar.loadSnackBar(params ? SuccessMessage.OPF_UPDATE : SuccessMessage.OPF_ADD, colorCodes.SUCCESS);
  }

  


  addAttachment(payload,successCallBack) {
    this._http.requestCall(Endpoints.ADD_ATTACHMENT_OPF, ApiMethod.POST, payload).subscribe(
      (res) => {
        successCallBack(res);
      },
      (err) => {
        this._router.navigate(['/channel-partner/bookings'])
        console.log(err); 
      }
    )
  }

viewBooking (bookingId,successCallBack,errorCallback){
    Endpoints['VIEW_BOOKING'] = `/opf/${bookingId.toString()}`;
    this._http.requestCall(Endpoints['VIEW_BOOKING'], ApiMethod.GET).subscribe(
      (res) => {
        successCallBack(res);
      },
      (error) => {
        errorCallback(error);
      });
  }

  listOPF(role,successResponseCallback, errorResponseCallback) {
    let endpoint = (role == Roles.Channel_Partner || role == Roles.Admin) ? Endpoints.LIST_OPF : Endpoints.SEARCH_BA
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        errorResponseCallback(error);
      });
  }

  addShippingAddress(addressPayload,successResponseCallback){
    this._http.requestCall(Endpoints.ADD_SHIPPING_ADDRESS, ApiMethod.POST, addressPayload).subscribe(
      (res) => { 
        successResponseCallback(res);
        this._snackBar.loadSnackBar(SuccessMessage.SHIPPING_ADDRESS_ADD, colorCodes.SUCCESS);
       },
      (error) => { 
        console.log(error); 
      }
    )
  }

  editParts(partsId, partsPayload, successResponseCallback) {
    Endpoints['EDIT_PARTS'] = `/opf-parts/${partsId.toString()}`;
    this._http.requestCall(Endpoints['EDIT_PARTS'], ApiMethod.PUT, partsPayload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error);
      });
  }

  deleteParts(partsId,successResponseCallback){
    Endpoints['DELETE_PARTS'] = `/opf-parts/${partsId.toString()}`;
    this._http.requestCall(Endpoints['DELETE_PARTS'], ApiMethod.DELETE).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error);
      });

  }
  editBooking(bookingId,bookingPayload, status?:string){
    Endpoints['EDIT_BOOKING'] = `/opf/${bookingId.toString()}`;
    this._http.requestCall(Endpoints['EDIT_BOOKING'], ApiMethod.PUT, bookingPayload).subscribe(
      (res) => {
        let successMessage = status ? 'OPF is '+ status : SuccessMessage.OPF_EDIT;
        this._snackBar.loadSnackBar(successMessage, colorCodes.SUCCESS);
        this.navigateToOPFList()
      },
      (error) => {
        console.log(error);
      });
  }

  editlistBooking(bookingId,bookingPayload, status?:string){
    Endpoints['EDIT_BOOKING'] = `/opf/${bookingId.toString()}`;
    this._http.requestCall(Endpoints['EDIT_BOOKING'], ApiMethod.PUT, bookingPayload).subscribe(
      (res) => {
        let successMessage = status ? 'OPF is '+ status : SuccessMessage.OPF_EDIT;
        this._snackBar.loadSnackBar(successMessage, colorCodes.SUCCESS);
     },
      (error) => {
        console.log(error);
      });
  }
  updateBooking(bookingId,bookingPayload, successCallBack){
    Endpoints['EDIT_BOOKING'] = `/opf/${bookingId.toString()}`;
    this._http.requestCall(Endpoints['EDIT_BOOKING'], ApiMethod.PUT, bookingPayload).subscribe(
      (res) => {
        successCallBack(res);
      },
      (error) => {
        console.log(error);
      });
  }
  refreshParts(payload, successResponseCallback){
    this._http.requestCall(Endpoints.RETRIGGER_OPF_PART, ApiMethod.POST,payload).subscribe(
      (res) => { successResponseCallback(res) },
      (error) => { console.log(error) }
    )
  }

    getAddressList(successResponseCallback){
    this._http.requestCall(Endpoints.ADD_SHIPPING_ADDRESS, ApiMethod.GET).subscribe(
      (res) => { successResponseCallback(res) },
      (error) => { console.log(error) }
    )
  }

  searchBooking(bookingPayload, successResponseCallback) {
    
    this._http.requestCall(Endpoints.ADD_BOOKING, ApiMethod.GETPARAMS, bookingPayload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }
  trainingVideosSort(bookingPayload, successResponseCallback) {
    
    this._http.requestCall(Endpoints.ADD_BOOKING, ApiMethod.GETPARAMS, bookingPayload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }
  trainingVideos(successResponseCallback) {
    
    this._http.requestCall(Endpoints.TRAINING_VIDEOS, ApiMethod.GET).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }


  
  exportBookingFilter(bookingPayload){
    this._http.requestCall(Endpoints.EXPORT_OPF,ApiMethod.DOWNLOAD_PARAMS,bookingPayload).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "BookingMaster.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this._snackBar.loadSnackBar(SuccessMessage.BOOKING_EXPORT, colorCodes.SUCCESS);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  exportAttachment(opfNumber) {
    Endpoints['ATTACHMENT_OPF'] = `/opf/attachment/${opfNumber.toString()}`;
    this._http.requestCall(Endpoints['ATTACHMENT_OPF'],ApiMethod.DOWNLOAD_BLOB).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: res.type });
        const url= window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = opfNumber + "-file";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this._snackBar.loadSnackBar("Success" +opfNumber + "-file Exported", colorCodes.SUCCESS);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getInvoiceDetails(invoiceNo, successCallback) {
    Endpoints['INVOICE_NCMS'] = `/opf/ncms/${invoiceNo}`;
    this._http.requestCall(Endpoints['INVOICE_NCMS'], ApiMethod.GET).subscribe(
      (res) => { successCallback(res) }
    );
  }


  importOpfCsv(file,successResponseCallback) {
    this._http.requestCall(Endpoints.IMPORT_OPF, ApiMethod.POST, file).subscribe(
      (res) => {
        successResponseCallback();
        this._snackBar.loadSnackBar("Success! OPF Imported", colorCodes.SUCCESS);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  importOpfPartsCsv(file,successResponseCallback) {
    this._http.requestCall(Endpoints.IMPORT_OPF_PARTS, ApiMethod.POST, file).subscribe(
      (res) => {
        successResponseCallback();
        this._snackBar.loadSnackBar("Success! OPF Parts Imported", colorCodes.SUCCESS);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  } 

  getOpfPartsQty(bookingPayload,successResponseCallback) {
    this._http.requestCall(Endpoints.GET_OPF_PARTS_QTY, ApiMethod.GETPARAMS, bookingPayload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }
  navigateToOPFList(){ 
    let roles = this._StorageService.getUserDetails().role;
    if (roles == Roles.Admin)   this._router.navigate(['beckman/bookings']);
    else if (roles == Roles.Channel_Partner)   this._router.navigate(['channel-partner/bookings']);
    else this._router.navigate(['beckman-billing/bookings']);

  }

  validateHospital(payload, successResponseCallback){
    this._http.requestCall(Endpoints.VALIDATE_HOSPITAL, ApiMethod.GETPARAMS, payload).subscribe(
      (res) => {
        successResponseCallback(res);
      })
  }

  validateOtl(payload, successResponseCallback){
    this._http.requestCall(Endpoints.VALIDATE_OTL, ApiMethod.GETPARAMS, payload).subscribe(
      (res) => {
        successResponseCallback(res);
      })
  }


  listChannelPartner(successResponseCallback) {
    this._http.requestCall(Endpoints.FILTER_CP, ApiMethod.GET).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }
 
  listHospital(successResponseCallback) {
    // let endpoint = (role == Roles.Channel_Partner) ?Endpoints.HOSPITAL_CP_SEARCH  : Endpoints.LIST_HOSPITAL ;
    this._http.requestCall(Endpoints.FILTER_HOSPITAL_AUDIT_LOG, ApiMethod.GET).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
      console.log(error);
      });
  }

  getListofOTLParts(successCallBack){
    this._http.requestCall(Endpoints.GET_OTL_LIST, ApiMethod.GET).subscribe(
      (res) => {
        successCallBack(res);
      });
  }
  exportOpfUploadTemplate (){
    this._http.requestCall(Endpoints.EXPORT_OPF_TEMPLATE, ApiMethod.DOWNLOAD).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "OPF-Upload-Template-Format.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this._snackBar.loadSnackBar(SuccessMessage.EXPORT_OPF_UPLOAD_TEMPLATE, colorCodes.SUCCESS);

        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  exportOpfTemplateInstructions(){
    this._http.requestCall(Endpoints.EXPORT_OPF_INSTRUCTIONS, ApiMethod.DOWNLOAD).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "Instructions-about-Upload-OPF-formats.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this._snackBar.loadSnackBar(SuccessMessage.EXPORT_OPF_UPLOAD_INSTRUCTIONS, colorCodes.SUCCESS);

        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getOPFAccessControl(successCallBack){
    this._http.requestCall(Endpoints.GET_BA_EDIT_ACCESS, ApiMethod.GET).subscribe(
      (res) => {
        successCallBack(res);
      });
  
  }

  getOPFAccessControls(payload,successCallBack){
      this._http.requestCall(Endpoints.GET_BA_EDIT_ACCESS, ApiMethod.GETPARAMS, payload).subscribe(
      (res) => {
        successCallBack(res);
      });
  
  }

  getPermissionAccessControls(payload,successResponseCallback) {
    this._http.requestCall(Endpoints.GET_CP_EDIT_ACCESS, ApiMethod.GETPARAMS, payload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }
  getActionPermission(payload, successCallback) {
    this._http.requestCall(Endpoints.PERMISSION_LIST, ApiMethod.GETPARAMS,payload).subscribe((actionList) => {
        successCallback(actionList);
      },
      (error) => { console.log(error); });
  }

  navigateToEditOPF(id){ 
    let roles = this._StorageService.getUserDetails().role;
    if (roles == Roles.Admin)   this._router.navigate(['/beckman/bookings/edit/' ,id ]);
    //else if (roles == Roles.Channel_Partner)    this._router.navigate(['/channel-partner/bookings/edit/',id ]);
    else if (roles == Roles.Channel_Partner)    this._router.navigate(['/channel-partner/bookings/editCP/',id ]); 
    else  this._router.navigate(['/beckman-billing/bookings/edit/' ,id ]);

  }

  exportCreditdebitNoteFilter(credit_debit_Payload){
    this._http.requestCall(Endpoints.EXPORT_CREDITDEBIT_NOTE,ApiMethod.DOWNLOAD_PARAMS,credit_debit_Payload).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "CreditDebitNoteIssuance.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}


